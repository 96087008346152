import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAccessToken } from '../utlis/auth'
import { getServerUri } from '../utlis/config'

const ClinicContext = createContext({})

export const useClinicContext = () => {
    return useContext(ClinicContext)
}

export const ClinicProvider = ({ children }) => {

    const [clinic, setClinic] = useState(null)

    const isSuperAdminClinic = clinic?.email === "admin@gmail.com"

    const handleFetchClinic = (accessToken) => {
        fetch(`${getServerUri()}/clinic`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken || getAccessToken()}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setClinic(response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        handleFetchClinic()
    }, [])

    return (
        <ClinicContext.Provider value={{ clinic, handleFetchClinic, isSuperAdminClinic }}>
            {children}
        </ClinicContext.Provider>

    )
}

export default ClinicContext