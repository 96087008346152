import React, { useEffect, useState } from "react";
import AdminSidebar from "../components/AdminSidebar";
import moment from "moment";
import Logo from "../assets/images/logo.png";
import LogoNew from "../assets/images/GROTrackTurquoise.png";
import Loading from "./Loading";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import Pagination from "../components/Pagination";
import { AiOutlineMenu } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { useClinicContext } from "../contexts/clinic";
import { getAccessToken as getUserAccessToken } from "../utlis/auth";
import { getServerUri } from "../utlis/config";

const ClinicList = () => {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [physician, setPhysician] = useState(null);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const location = useLocation();
    const [pageInput, setPageInput] = useState(1);
    const { isSuperAdminClinic } = useClinicContext()


    useEffect(() => {
        fetch(`${getServerUri()}/admin/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getUserAccessToken()}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setPhysician(response.adminProfile);
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
            });
    }, []);

    const getClinics = async (page, name, email) => {
        setReady(false);

        const searchParams = new URLSearchParams(location.search);
        searchParams.set('name', name);
        searchParams.set('email', email);
        searchParams.set('page', page);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        await fetch(`${getServerUri()}/admin/clinic/search?page=${page}${name ? `&name=${name}` : ''}${email ? `&email=${email}` : ''}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getUserAccessToken()}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    console.log(response.clinics, "clinincData")
                    setData(response.clinics.data);
                    setTotalPages(response.clinics.last_page);
                    setCurrentPage(response.clinics.current_page);
                    setPageInput(response.clinics.current_page);
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
            }).finally(() => {
                setReady(true);
            });
    }

    const getAccessToken = (url, clinic_id) => {
        setReady(false);
        fetch(`${getServerUri()}/admin/clinic/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getUserAccessToken()}`,
            },
            body: JSON.stringify({ clinic_id: clinic_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    if (url === 'https://grotrack.online') {
                        Cookies.set('access_token', response.access_token, { domain: '.grotrack.online' });
                        Cookies.set('expires_at', response.expires_at, { domain: '.grotrack.online' });

                        window.open("https://grotrack.online/admin/access_account", '_blank');
                    } else if (url === "https://validation.grotrack.online/") {
                        const newTab = window.open(url, '_blank');

                        newTab.onload = function () {
                            newTab.sessionStorage.setItem('temporary_access_token', response.access_token);
                        };
                    } else {
                        window.open(`https://clinic.grotech.io/admin/access_account/${response.access_token}/${response.expires_at}`, '_blank');
                    }
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
            }).finally(() => {
                setReady(true);
            });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getClinics(page, name, email);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const nam = searchParams.get('name') || '';
        setName(nam);
        const ema = searchParams.get('email') || '';
        setEmail(ema);
        const pag = parseInt(searchParams.get('page')) || 1;
        setCurrentPage(pag);
        setPageInput(pag);

        getClinics(pag, nam, ema);
    }, []);

    const toggleCertification = async (user) => {
        Swal.fire({
            title: `Are you sure you want to ${user.in_training ? 'certify' : 'remove certifcation of'} ${user.name}?`,
            icon: user.in_training ? 'success' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setReady(false);
                fetch(`${getServerUri()}/admin/clinic/toggle-training`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getUserAccessToken()}`,
                    },
                    body: JSON.stringify({ clinic_id: user.id, in_training: !user.in_training }),
                })
                    .then((response) => response.json())
                    .then(async (response) => {
                        if (response.success) {
                            await getClinics(currentPage, name, email);
                        } else {
                            setMessage(response.message);
                        }
                    })
                    .catch((error) => {
                        setMessage("Some Error Occured. Please Try Again Later");
                    }).finally(() => {
                        setReady(true);
                    });
            }
        })
    }

    const toggleStudyMode = async (user) => {
        Swal.fire({
            title: `Are you sure you want to ${user.study_mode_enabled ? 'disable' : 'enable'} study mode for ${user.name}?`,
            icon: user.in_training ? 'success' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setReady(false);
                fetch(`${getServerUri()}/admin/clinic/toggle-study-mode`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getUserAccessToken()}`,
                    },
                    body: JSON.stringify({ clinic_id: user.id, study_mode_enabled: !user.study_mode_enabled }),
                })
                    .then((response) => response.json())
                    .then(async (response) => {
                        if (response.success) {
                            await getClinics(currentPage, name, email);
                        } else {
                            setMessage(response.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error, "study-api-error")
                        setMessage("Some Error Occured. Please Try Again Later");
                    }).finally(() => {
                        setReady(true);
                    });
            }
        })
    }

    return (
        <>
            <main
                className={`mx-auto w-full flex flex-col p-4 font-font-2 tracking-wider ${ready ? "" : "hidden"
                    }`}
            >
                <nav className="flex items-center border-b-2 border-background-2 pb-6 pt-4 mx-10">
                    <NavLink to="/">
                        <img className="h-12" src={LogoNew} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto text-3xl text-text-2 font-bold" style={{ transform: `translate(-40px)` }}>
                        Clinics
                    </h1>
                </nav>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && (
                    <p className="mt-8 text-center text-green-600">{success}</p>
                )}
                <section className="flex">
                    <div className="div px-10 w-full my-10">
                        <h2 className="font-font-2 text-2xl mb-4 text-center text-white">CLINIC LIST</h2>

                        <form onSubmit={(e) => { e.preventDefault(); getClinics(1, name, email) }}>
                            <div className="w-full flex space-x-2 bg-transparent gap-2 my-4 items-end">
                                <div className="w-1/4 items-center">
                                    <label htmlFor="nameInput" className="text-gray-500 flex-none pr-2">Name:</label>
                                    <div className="flex-1">
                                        <input type="text" id="nameInput" className="bg-gray-500 text-white py-1 px-3 border-b border-gray-500 focus:outline-none w-full" value={name} onChange={(e) => { setName(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="w-1/4 items-center">
                                    <label htmlFor="emailInput" className="text-gray-500 flex-none pr-2">Email:</label>
                                    <div className="flex-1">
                                        <input type="text" id="emailInput" className="bg-gray-500 text-white py-1 px-3 border-b border-gray-500 focus:outline-none w-full" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="w-1/4">
                                    <button type="submit" className="bg-accent-1 hover:bg-accent-700 text-white font-bold border-b border-accent-1 py-1 px-10 rounded focus:outline-none focus:shadow-outline">Search</button>
                                </div>
                            </div>
                        </form>

                        <div className="max-w-screen overflow-x-scroll" style={{ transform: "rotateX(180deg)" }}>
                            <table className="min-w-full" style={{ transform: "rotateX(180deg)" }}>
                                <thead className="">
                                    <tr>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-4">
                                            #
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            Registration Date
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            Invalid Image %
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            Analysis Created
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            Training
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            Action
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            Study Mode
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            PDFs
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...data].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map((value, index) => {
                                        return (
                                            <tr className="border-b" key={index}>
                                                <td className="px-3 py-2 whitespace-nowrap border text-center text-gray-400">{((currentPage - 1) * 20) + (index + 1)}</td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    {value.name}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    {value.email}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    {dayjs.unix(value.created_at).format("MM-DD-YYYY")}
                                                </td>
                                                <td className={`px-3 py-2 whitespace-nowrap border ${value.invalid_image_percentage >= 20 && value.hair_analysis_count >= 5 ? "text-red-500" : "text-gray-400"} font-normal`}>
                                                    {value.invalid_image_percentage === 'NA' ? 'NA' : `${Math.floor(value.invalid_image_percentage)} %`}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    {value.hair_analysis_count}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    {value.in_training ? 'In Training' : 'Certified'}
                                                    <button className={`block hover:text-white ${value.in_training ? 'text-green-500' : 'text-red-500'}`} onClick={(e) => { toggleCertification(value) }}>
                                                        {value.in_training ? 'Certify' : 'Cancel Certification'}
                                                    </button>
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    <button className={`block hover:text-white text-green-500`} onClick={(e) => { getAccessToken("https://grotrack.online", value.id) }}>
                                                        Log In grotrack.online
                                                    </button>
                                                    {!!isSuperAdminClinic && (
                                                        <button className={`block hover:text-white text-green-500`} onClick={(e) => { getAccessToken("", value.id) }}>
                                                            Log In clinic.grotech.io
                                                        </button>
                                                    )}
                                                    {value?.role === 'admin' && (
                                                        <button className={`block hover:text-white text-green-500`} onClick={(e) => { getAccessToken("https://validation.grotrack.online/", value.id) }}>
                                                            Log In validation.grotrack.online
                                                        </button>
                                                    )}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    <button onClick={(e) => { toggleStudyMode(value) }} className={`block hover:text-white ${value.study_mode_enabled ? `text-red-500` : `text-green-500`}`} >
                                                        {value.study_mode_enabled ? 'Disable' : 'Enable'}
                                                    </button>
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-400 font-normal">
                                                    <button className={`block hover:text-white text-green-500`}>
                                                        <a href={value.user_agreement_pdf} target="_blank" className="text-inherit no-underline">
                                                            User Agreement
                                                        </a>
                                                    </button>
                                                    <button className={`block hover:text-white text-green-500`}>
                                                        <a href={value.hipaa_agreement_pdf} target="_blank" className="text-inherit no-underline">
                                                            Hipaa Agreement
                                                        </a>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <Pagination pageInput={pageInput} currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} setPageInput={setPageInput} />
                    </div>
                </section>
            </main>
            <Loading message={"Loading"} ready={ready} />
        </>
    );
};

export default ClinicList;