export const getHairComparisonPercentage = (baseline, current) => {
    if(baseline > 0){
        const percentage = ((current - baseline) / current * 100).toFixed(0);

        switch (Math.sign(percentage)){
            case 1:
                return  `+${percentage}`;
            case -1:
                return  percentage;
            default:
                return  percentage;
        }
    }
    return 0;
}