
import { useState } from 'react'
import { LOCAL_STORAGE_KEYS, SERVER_TYPES, SESSION_STORAGE_KEYS } from '../constants'
import { useClinicContext } from '../contexts/clinic';

export default function ServerIndicator() {
  const { isSuperAdminClinic } = useClinicContext()
  
    const [serverType, setServerType] = useState(() => {
        const previousServerType = localStorage.getItem(LOCAL_STORAGE_KEYS.SERVER_TYPE);

        if (previousServerType && previousServerType === SERVER_TYPES.PRODUCTION) return SERVER_TYPES.PRODUCTION;
        if (previousServerType && previousServerType === SERVER_TYPES.STAGING) return SERVER_TYPES.STAGING;
        
        return SERVER_TYPES.PRODUCTION;
    })
    const [isOpen, setIsOpen] = useState(false)

    const toggleSelect = () => setIsOpen(!isOpen)

    const changeServerType = (type) => {
        setServerType(type)
        setIsOpen(false)

        localStorage.setItem(LOCAL_STORAGE_KEYS.SERVER_TYPE, type)
        
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.EXPIRES_AT);
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.TEMPORARY_ACCESS_TOKEN)
        window.location.href = '/';
    }

    const displayServerIndicator = isSuperAdminClinic || serverType === SERVER_TYPES.STAGING

    if(!displayServerIndicator) return null;

    return (
        <div className="fixed bottom-4 left-4 z-50">
            <div
                className="flex items-center space-x-2 bg-white bg-opacity-20 rounded-full shadow-md px-3 py-2 cursor-pointer"
                onClick={toggleSelect}
                tabIndex={0}
                role="button"
                aria-haspopup="listbox"
                aria-expanded={isOpen}
            >
                <div className="w-2 h-2 rounded-full bg-green-500"></div>
                <span className="text-sm font-medium text-white">{serverType}</span>
            </div>
            {isOpen && (
                <div className="absolute bottom-full left-0 mb-2 bg-white rounded-md shadow-lg">
                    <ul
                        className="py-1"
                        role="listbox"
                        aria-label="Select server type"
                    >
                        <li>
                            <button
                                className="w-full text-left capitalize px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                                onClick={() => changeServerType(SERVER_TYPES.STAGING)}
                                role="option"
                                aria-selected={serverType === SERVER_TYPES.STAGING}
                            >
                                staging
                            </button>
                        </li>
                        <li>
                            <button
                                className="w-full text-left capitalize px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                                onClick={() => changeServerType(SERVER_TYPES.PRODUCTION)}
                                role="option"
                                aria-selected={serverType === SERVER_TYPES.PRODUCTION}
                            >
                                production
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}