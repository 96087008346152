export const SERVER_TYPES = {
    STAGING: 'staging',
    PRODUCTION: 'production',
}

export const LOCAL_STORAGE_KEYS = {
    SERVER_TYPE: 'server_type',
    ACCESS_TOKEN: 'access_token',
    EXPIRES_AT: 'expires_at',
}

export const SESSION_STORAGE_KEYS = {
    TEMPORARY_ACCESS_TOKEN: 'temporary_access_token',
}